<div class="dialog-container"
  [ngClass]="data?.class">
  <!-- Dialog title -->
  <div class="dialog-title-container"
    [ngClass]="{'host-header-wrapper': headerComponentRef, 'hide-dialog-header': data.hideHeader}">
    <div class="main-title">
      <!--Load dynamic header component-->
      <ng-template #hostHeaderComponent></ng-template>
      <!--Header with title and close icon-->
      <h1 mat-dialog-title
        *ngIf="!headerComponentRef && !data.hideHeader">
        <i *ngIf="data.titleIcon"
          class="{{data.titleIcon}}"></i>
        {{data.title}}
      </h1>
      <app-vi-action-button class="close-dialog"
        *ngIf="!data.hideHeader"
        [action]="closeAction"
        [showAccessibilityTooltip]="true"
        (actionClick)="closeDialog($event)">
      </app-vi-action-button>
    </div>
    <p *ngIf="data?.subtitle"
      class="sub-title">
      {{data?.subtitle}}
    </p>
  </div>
  <!-- Dialog component -->
  <!-- Transclude outer component -->
  <div mat-dialog-content
    class="dialog-container">
    <!-- Dialog content -->
    <div mat-dialog-content
      *ngIf="data?.content"
      [innerHtml]="data.content">
    </div>

    <ng-template #hostComponent></ng-template>
  </div>

  <!-- Dialog buttons composed from 2 buttons: primary and secondary -->
  <div mat-dialog-actions
    [ngClass]="{'disclaimer': data?.disclaimer}"
    *ngIf="data.primaryButton || data.secondaryButton">
    <div class="disclaimer-container truncate"
      *ngIf="data?.disclaimer">
      <i role="contentinfo"
        class="i-vi-info"></i>
      <span>{{data.disclaimer}}</span>
    </div>
    <div>
      <app-vi-action-button *ngIf="data.primaryButton"
        [action]="data.primaryButton.action"
        [isDisabled]="data.primaryButton.isDisabled || data.primaryButton?.action?.isDisabled"
        [type]="data.primaryButton.type"
        (actionClick)="handleClick({action: $event, isDialogClose: !data.primaryButton.disableClickClose})">
      </app-vi-action-button>
      <app-vi-action-button *ngIf="data.secondaryButton"
        [action]="data.secondaryButton.action"
        [isDisabled]="data.secondaryButton.isDisabled"
        [type]="data.secondaryButton.type"
        (actionClick)="handleClick({action: $event, isDialogClose: true})">
      </app-vi-action-button>
    </div>
  </div>
</div>