<ul role="menu"
  [ngClass]="{show: show}"
  [attr.aria-hidden]="!show">
  <li role="presentation">
    <app-user-details [userDetails]="userDetails"
      role="menuitem"></app-user-details>
  </li>
  <li role="presentation"
    *ngIf="isSwitchDirectoryEnabled"
    class="action-button">
    <div role="menuitem">
      <a class="truncate"
        role="button"
        tabindex="0"
        (appAccessibleClick)="openSwitchDirectoryDialog()"
        [attr.aria-label]="switchDirectoryAction.title"
        [title]="switchDirectoryAction.title">
        {{ switchDirectoryAction.title }}
      </a>
    </div>
  </li>
  <li role="presentation"
    class="action-button">
    <div role="menuitem">
      <a class="truncate"
        role="button"
        tabindex="0"
        (appAccessibleClick)="signOut()"
        [attr.aria-label]="signOutAction.title"
        [title]="signOutAction.title">
        {{ signOutAction.title }}
      </a>
    </div>
  </li>
</ul>
