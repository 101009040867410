<section class="alert-message"
  [ngClass]="type">
  <div class="alert-icon"
    role="none"
    aria-hidden="true">
    <i *ngIf="type === 'error'"
      class="i-vi-error"></i>
    <i *ngIf="type === 'warning'"
      class="i-vi-warning"></i>
      <i *ngIf="type === 'info'"
      class="i-vi-info"></i>
  </div>

  <div class="alert-content">
    <span *ngIf="!isContentHtml"
      class="alert-text"
      role="alert"
      [title]="contentTitle || content"
      [attr.aria-label]="contentTitle || content">
      {{ content }}
    </span>
    <span *ngIf="isContentHtml"
      class="alert-text"
      role="alert"
      [title]="contentTitle || content"
      [attr.aria-label]="contentTitle || content"
      [innerHTML]="content">
    </span>
    <a *ngIf="linkText"
      [attr.role]="isButton? 'button': 'link'"
      class="alert-link"
      [href]="isButton? 'javascript:void(0)' : linkPath"
      [title]="linkText"
      [attr.aria-label]="linkText"
      [attr.target]="isButton? '': '_blank'"
      (appAccessibleClick)="onClick()">
      {{ linkText }}
    </a>
  </div>
</section>
