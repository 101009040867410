<div class="container">
  <div class="title">
    {{resources?.ConfirmExitIndexingTitle}}
  </div>
  <div class="description">
    {{resources?.ConfirmExitIndexingDescription}}
  </div>
  <div class="buttons-container">
    <app-vi-action-button [action]="leaveIndexingAction"
      [type]="ActionButtonType.PRIMARY"
      [minWidth]="LEAVE_INDEXING_MIN_BUTTON_WIDTH"
      [focusOnInit]="true"
      (actionClick)="onLeavePage($event)">
    </app-vi-action-button>
    <app-vi-action-button [action]="backToIndexingAction"
      [type]="ActionButtonType.SECONDARY"
      [minWidth]="BACK_TO_INDEXING_MIN_BUTTON_WIDTH"
      (actionClick)="onBackToIndexing()">
    </app-vi-action-button>
  </div>
</div>
