<div [appAccessibilitySubMenuHandler]="presets.length && expanded"
  (toggleFunc)="handleActionButtonClick($event)"
  [toggleFuncOnNullRelatedTarget]="true"
  (keyup.esc)="handleEscClick($event)">
  <!-- Preset button -->
  <div [appAccessibilityContentOnHoverHandler]
    (contentOnHover)="onMouseOver($event)">
    <app-vi-action-button [sizeClass]="sizeClass"
      #PresetButton
      [width]="width"
      [maxWidth]="maxWidth"
      [action]="action"
      [hasOpenerIcon]="'true'"
      [expanded]="expanded"
      [responsiveState]="responsiveState"
      [internal]="internal"
      [ariaOwns]="MENU_ID"
      [type]="buttonType"
      [hasNotificationTooltip]="true"
      [isDisabled]="isDisabled"
      (actionClick)="handleActionButtonClick($event)"
      (focusIn)="handleFocusInActionButton()">
    </app-vi-action-button>
    <app-vi-notification-tooltip *ngIf="showTabTooltip"
      [targetElement]="presetButton"
      [direction]="Direction.LEFT"
      [centerArrow]="true"
      (notificationDismiss)="hideTooltip()"
      [notificationText]="tooltipNotificationTextOnTabChange()">
    </app-vi-notification-tooltip>
    <app-vi-notification-tooltip *ngIf="(!isDisabled) && (showTooltip && !expanded)"
      (escPressed)="showTooltip = false"
      [addAnimation]="false"
      [targetElement]="presetButton"
      [direction]="Direction.LEFT"
      [centerArrow]="true"
      [notificationText]="generateTooltipText()">
    </app-vi-notification-tooltip>
  </div>
  <!-- Container for custom insights list -->
  <div class="dropdown presets custom-container"
    #customInsightsPresetsContainer
    (mouseleave)="onCustomInsightContainerOut($event)"
    *ngIf="isCustomPresetsListEnabled && customInsightsExpanded">
    <div class="sub-dropdown">
      <ul class="presets-custom-insights-list"
        [appCdkTrapFocus]="true"
        id="customInsightsViewPresets"
        name="customInsightsViewPresets">
        <ng-container *ngFor="let ins of presetInsights">
          <li *ngIf="ins.type === PresetInsightType.CUSTOM"
            data-stop-propagation="true"
            (mousedown)="$event.preventDefault()">
            <div class="c-checkbox">
              <label class="c-label"
                for="checkboxId{{ ins.value }}">
                <input data-stop-propagation="true"
                  (keyup.arrowleft)="onCustomInsightArrow(false, $event)"
                  (change)="selectPresetInsight(ins)"
                  type="checkbox"
                  id="{{ PRESET_ID_PREFIX + ins.value }}"
                  name="checkboxId{{ ins.value }}"
                  [(ngModel)]="ins.selected"
                  role="checkbox"
                  [attr.aria-disabled]="ins.disabled"
                  [attr.disabled]="ins.disabled ? '' : null"
                  [attr.aria-checked]="ins.selected"
                  attr.aria-label="{{resources?.PresetCustomViewTitle}} {{ ins.name }}" />
                <span aria-hidden="true"
                  [attr.disabled]="ins.disabled ? '' : null"
                  [title]="ins.name"
                  attr.aria-label="{{resources?.PresetCustomViewTitle}} {{ ins.name }}">
                  {{ ins.name }}
                </span>
              </label>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>

  </div>
  <!-- Container for insights + preset list -->
  <div *ngIf="expanded"
    class="dropdown presets {{sizeClass}}"
    data-stop-propagation="true"
    (scroll)="customInsightsExpanded = false"
    #insightsPresetsContainer>
    <!-- Container for preset list -->
    <div class="sub-dropdown">
      <label [attr.aria-label]="resources?.UseScenarioLabel"
        [title]="resources?.UseScenarioLabel"
        data-stop-propagation="true">{{resources?.UseScenarioLabel}}</label>
      <ul #PresetsContainer
        [attr.id]="MENU_ID"
        class="presets-list"
        role="listbox"
        [attr.aria-label]="resources?.Presets"
        [attr.title]="resources?.Presets"
        (keyup.arrowDown)="handleArrowDown($event)"
        (keyup.arrowUp)="handleArrowUp($event)">
        <li *ngFor="let preset of presets"
          tabindex="0"
          (appAccessibleClick)="selectPreset(preset)"
          id="{{preset.name + OPTION_ID_SUFFIX}}"
          role="option"
          class="preset"
          [ngClass]="{ selected: preset.selected }"
          [attr.aria-selected]="preset.selected"
          attr.aria-label="{{resources?.UseScenarioLabel}} {{preset.name}}"
          [attr.aria-controls]="convertPresetToString(preset)">
          <label data-stop-propagation="true"
            for="{{preset.name + OPTION_ID_SUFFIX}}"
            [attr.title]="preset.name"
            attr.aria-label="{{resources?.UseScenarioLabel}} {{preset.name}}">
            {{preset.name}}
          </label>
        </li>
      </ul>
    </div>
    <!-- Container for insights list -->
    <div class="sub-dropdown">
      <label id="subDropdown"
        for="customViewPresets"
        [attr.aria-label]="resources?.PresetCustomViewTitle"
        [title]="resources?.PresetCustomViewTitle">
        {{ resources?.PresetCustomViewTitle }}
      </label>
      <ul class="presets-insights-list"
        id="customViewPresets"
        name="customViewPresets">
        <!-- Custom preset insights button -->
        <div *ngIf="isCustomPresetsListEnabled">
          <li #customInsightsPresetsButton
            class="preset"
            tabindex="0"
            (keyup.arrowleft)="onCustomInsightArrow(false, $event)"
            (keyup.arrowright)="onCustomInsightArrow(true, $event)"
            (mousedown)="$event.preventDefault()"
            (mouseover)="onCustomInsightHover(true, $event)"
            (mouseleave)="onCustomInsightHover(false, $event)">
            <div class="c-checkbox custom-insights-label">
              <label class="c-label">
                <span aria-hidden="true"
                  [title]="'Custom insights'"
                  attr.aria-label="'Custom insights'">
                  {{resources?.LabelCustomInsightsPresetButton}}
                </span>
                <i class="i-vi-arrow-right"></i>
              </label>
            </div>
          </li>
        </div>
        <ng-container *ngFor="let ins of presetInsights">
          <li *ngIf="isCustomPresetsListEnabled ? ins.type === PresetInsightType.REGULAR : true"
            (mousedown)="$event.preventDefault()">
            <div class="c-checkbox">
              <label class="c-label"
                for="checkboxId{{ ins.value }}">
                <input (change)="selectPresetInsight(ins)"
                  type="checkbox"
                  id="{{ PRESET_ID_PREFIX + ins.value }}"
                  name="checkboxId{{ ins.value }}"
                  [(ngModel)]="ins.selected"
                  role="checkbox"
                  [attr.aria-disabled]="ins.disabled"
                  [attr.disabled]="ins.disabled ? '' : null"
                  [attr.aria-checked]="ins.selected"
                  attr.aria-label="{{resources?.PresetCustomViewTitle}} {{ ins.name }}" />
                <span aria-hidden="true"
                  [attr.disabled]="ins.disabled ? '' : null"
                  [title]="ins.name"
                  attr.aria-label="{{resources?.PresetCustomViewTitle}} {{ ins.name }}">
                  {{ ins.name }}
                </span>
              </label>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>