<section [ngClass]="{'show-animation': startAnimation, 'stretch' : stretch, 'no-arrow': !showArrow}"
  class="{{ direction }} {{ tooltipClass }}">
  <i *ngIf="hasExitButton"
    tabindex="0"
    class="vi-delete-parent i-vi-close-big"
    [attr.aria-label]="resources?.Close"
    role="button"
    (appAccessibleClick)="dismiss()"></i>
  <span *ngIf="notificationText"
    [innerHTML]="notificationText"></span>
  <div *ngIf="hasCheckbox"
    class="c-checkbox">
    <label class="c-label"
      data-stop-propagation="true">
      <input [attr.aria-labelledby]="checkboxTextElementId"
        (change)="selectCheckbox()"
        type="checkbox"
        [(ngModel)]="checkboxSelected"
        role="checkbox"
        [attr.aria-checked]="checkboxSelected" />
      <span [attr.id]="checkboxTextElementId"
        aria-hidden="true"
        data-stop-propagation="true"
        role="text"
        [attr.aria-label]="checkboxText"
        [title]="checkboxText">{{ checkboxText }}</span>
    </label>
  </div>
  <ng-content select="[notificationContent]"></ng-content>
  <div *ngIf="addOverlay"
    class="overlay"
    aria-hidden="true"
    role="none"
    tabindex="-1"></div>
</section>
