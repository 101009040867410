<div class="main {{subMenuTypeClass}}"
  [ngClass]="{
    'hide-side-menu': !isAuthenticated || (isZeroAccounts$ | async),
    'side-menu-opened': isSideMenuOpened && isAuthenticated,
    'side-sub-menu-opened': isSubMenuOpened && isAuthenticated,
    'mobile': isMobile
  }">
  <div *ngIf="!isMobile && isSideMenuOpened && isAuthenticated"
    class="overlay"
    [ngClass]="{'side-menu-opened': isSideMenuOpened && isAuthenticated}"></div>
  <div class="header"
    id="headerWrapper">
    <ngx-loading-bar [color]="progressBarColor"
      [includeSpinner]="false"> </ngx-loading-bar>
    <app-vi-header [isUserAuthenticated]="isAuthenticated"
      [preset]="headerPreset"
      [isZeroAccounts]="isZeroAccounts$ | async">
    </app-vi-header>
    <ng-container *ngIf="isAuthenticated">
      <vi-main-sidebar></vi-main-sidebar>
      <app-vi-sidebar (openAbout)="handleShowAbout()"></app-vi-sidebar>
    </ng-container>
    <section class="toaster-area"
      aria-live="polite"
      toastContainer></section>
  </div>
  <vi-side-menu [isMobile]="isMobile"
    *ngIf="isAuthenticated && ready && showSideMenu"
    class="side-menu">
  </vi-side-menu>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
  <app-vi-spinner *ngIf="!ready && (userDetailsError | async) !== true">
  </app-vi-spinner>
</div>
