<button #button
  type="button"
  class="action-button {{ sizeClass }} {{class}} {{responsiveState}} {{type}}"
  id="{{ uid }}"
  [attr.role]="isLink ? 'link': 'button'"
  (appAccessibleClick)="selectAction(action, $event)"
  [appSpaceClickEnabled]="true"
  (mouseover)="handleMouseOver()"
  (mouseleave)="handleMouseLeave()"
  (focusin)="handleFocusIn()"
  [ngClass]="{internal: internal,
    selected: selected || action?.selected,
    accessible: accessibilityMode,
    whiteFocus: whiteFocus,
    'image-button':
      action?.type === UIActionType.IMAGE && (action?.value || action?.style),
    'expanded': expanded}"
  [style.height]="height"
  [style.width]="width"
  [style.min-width]="minWidth"
  [style.max-width]="maxWidth"
  [attr.aria-haspopup]="action?.hasDrop"
  [attr.aria-expanded]="action?.hasDrop ? (action?.selected ? action?.selected : expanded) : null"
  [attr.aria-pressed]="action?.hasPressedMode ? action?.selected && action?.selectable : null"
  [attr.title]="!hasNotificationTooltip ? (action?.titleAttribute || action?.title) : ''"
  [attr.aria-labelledby]="ariaLabelledBy"
  [attr.aria-label]="action?.titleAttribute || action?.title"
  [attr.aria-disabled]="isDisabled"
  [attr.aria-owns]="expanded ? ariaOwns : null"
  [attr.disabled]="isDisabled ? '' : null"
  appAccessibilityTooltip
  [showAccessibilityTooltip]="showAccessibilityTooltip && accessibilityMode && (action?.titleAttribute !== '')"
  [attr.tabindex]="activeContainer ? -1 : 0">
  <i class="{{ mainActionIcon }}"
    *ngIf="mainActionIcon && !isLoading && mainActionIcon !== UIActionIcon.SPINNER"
    aria-hidden="true"></i>
  <i class="spinner"
    *ngIf="isLoading || mainActionIcon === UIActionIcon.SPINNER"
    aria-hidden="true">
    <app-vi-spinner [size]="'tiny'"> </app-vi-spinner>
  </i>
  <span *ngIf="action?.title">{{ action?.title }}</span>
  <i *ngIf="action?.title && hasOpenerIcon"
    class="arrow"
    aria-hidden="true"
    [ngClass]="{'i-vi-arrow-down': !expanded, 'i-vi-arrow-up': expanded}">
  </i>
  <span class="preview"
    *ngIf="action?.preview">({{resources?.Preview}})</span>
  <!-- picture -->
  <div *ngIf="isUIActionType(UIActionType.IMAGE) && (action?.value || action?.style)"
    role="img"
    [attr.aria-label]="action?.titleAttribute">
    <picture class="custom-button"
      [ngClass]="{'empty-image': action?.isDisabled || !fallbackUrl}"
      [attr.title]="action?.titleAttribute">
      <div *ngIf="action?.style"
        class="c-image"
        [ngStyle]="action?.style"></div>
      <img *ngIf="!action?.style && action?.value"
        id="persona_{{ action?.id }}"
        [src]="action?.value"
        [appViImageFallback]="action?.value ? fallbackUrl : ''"
        [attr.alt]="action?.titleAttribute" />
    </picture>
  </div>
  <ng-content></ng-content>
</button>
