<div class="m-persona-3 {{imageShapeClass}}">
  <div class="item {{sizeClass}}"
    role="tab"
    *ngFor="let entity of entities;index as i">
    <picture #pictureObject
      (appAccessibleClick)="selectEntity(entity)"
      appAccessibilityAnnouncer
      listenedEvent="enter"
      [announceText]="getShowingEntityProfileText(entity)"
      [attr.title]="hasTooltip ? null : entity?.titleAttribute"
      [attr.alt]="entity?.titleAttribute"
      [attr.tabindex]="activeContainer ? -1 : 0"
      id="entity{{entity.id}}{{entity.name}}"
      [ngClass]="{selected: entity.selected}"
      [appAccessibilityContentOnHoverHandler]
      (contentOnHover)="showEntityTooltip(entity, $event)"
      (focus)="showEntityTooltip(entity, true)"
      (focusout)="showEntityTooltip(entity, false)"
      autofocus>
      <div class="c-image f-round"
        [ngStyle]="entity.style"></div>
    </picture>
    <app-vi-notification-tooltip *ngIf="hasTooltip && entity.showTooltip"
      class="notification"
      [targetElement]="listPictureItems?.toArray()[i]"
      [stretch]="stretchNotification"
      [addAnimation]="false">
      <ng-content select="[imageTooltipContent]"
        notificationContent></ng-content>
    </app-vi-notification-tooltip>
  </div>
  <div class="item placeholder"
    *ngIf="overflow && !expanded">
    <div class="circle"
      [attr.tabindex]="activeContainer ? -1 : 0"
      (mouseup)="toggleWidgetMouse($event)"
      (keyup.enter)="toggleWidget(true)"
      role="button"
      [attr.title]="resources?.ShowMore">
      +{{ overflow }}
    </div>
  </div>
</div>