<div class="download-closed-captions-container"
  [ngClass]="{'audio-effects': data?.showIncludeAudioEffects, 'speakers': data?.showIncludeSpeakers}">
  <label class="format-label"
    for="formatSelectId"
    id="{{data.prefixId}}_downloadClosedCaptions">
    {{resources?.SelectFormat}}
  </label>
  <vi-fluent-ui-select [id]="formatSelectId"
    [action]="formatFilterAction"
    [actionList]="downloadCaptionsOptions"
    [ariaLabelledBy]="data.prefixId + '_downloadClosedCaptions'"
    [selectedAction]="selectedFormat"
    (selectedActionChange)="selectedFormat=$event">
  </vi-fluent-ui-select>
  <div *ngIf="data?.showIncludeAudioEffects"
    class="c-checkbox"
    [ngClass]="{accessible: accessibilityMode}">
    <label class="c-label"
      for="include-audio-checkbox">
      <input id="include-audio-checkbox"
        type="checkbox"
        [ngModel]="includeAudioEffects"
        (change)="includeAudioEffects = !includeAudioEffects"
        [attr.aria-checked]="includeAudioEffects"
        [attr.aria-label]="resources?.IncludeAudioEffects"
        [attr.title]="resources?.IncludeAudioEffects" />
      <span class="checkbox-text"
        role="none">
        {{ resources?.IncludeAudioEffects}}
      </span>
    </label>
  </div>
  <div *ngIf="data?.showIncludeSpeakers"
    class="c-checkbox"
    [ngClass]="{accessible: accessibilityMode}">
    <label class="c-label"
      for="include-speakers-checkbox">
      <input id="include-speakers-checkbox"
        type="checkbox"
        [ngModel]="includeSpeakers"
        (change)="includeSpeakers = !includeSpeakers"
        [attr.aria-checked]="includeSpeakers"
        [attr.aria-label]="resources?.includeSpeakers"
        [attr.title]="resources?.includeSpeakers" />
      <span class="checkbox-text"
        role="none">
        {{ resources?.includeSpeakers}}
      </span>
    </label>
  </div>
  <div class="buttons">
    <app-vi-action-button class="download-btn"
      [action]="downloadAction"
      [type]="ActionButtonType.PRIMARY"
      (actionClick)="onDownloadClicked()">
    </app-vi-action-button>
    <app-vi-action-button class="cancel-btn"
      [action]="cancelAction"
      [type]="ActionButtonType.SECONDARY"
      (actionClick)="onCancelClicked()">
    </app-vi-action-button>
  </div>
</div>