<div class="container"
  [ngClass]="{accessible: accessibilityMode}">
  <button tabindex="0"
    role="switch"
    [title]="title"
    [attr.aria-label]="title"
    [attr.aria-checked]="checked"
    [disabled]="disabled"
    [ngClass]="{on: checked, 'off' : !checked}"
    (viUiAccessibleClick)="onChange()"
    viUiDebounce
    (debounceEvent)="debounceCheckedChange()"
    [debounceEventNames]="['click', 'keyup.enter']"
    [debounceValue]="checked">
    <span class="circle"></span>
  </button>
</div>
