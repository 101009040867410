<div class="container {{theme?.svgPath}}"
  role="radio"
  appAccessibilityTooltip
  [showAccessibilityTooltip]="accessibilityMode"
  (focus)="handleFocus()"
  [ngClass]="{selected:theme?.selected, accessible: accessibilityMode}"
  tabindex="0"
  [attr.aria-label]="titleValue"
  (appAccessibleClick)="selectTheme()"
  [attr.aria-checked]="!!theme?.selected"
  [attr.title]="titleValue">
  <svg width="66"
    role="img"
    height="42"
    viewBox="0 0 66 42"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true">
    <rect *ngFor="let elem of svgElements"
      class="{{elem.class}}"
      [attr.x]="elem.x"
      [attr.y]="elem.y"
      [attr.width]="elem.width"
      [attr.height]="elem.height" />
  </svg>
</div>
