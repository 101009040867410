<div class="actions-menu-container"
  [appAccessibilitySubMenuHandler]="actionList.length && expanded"
  (toggleFunc)="selectAction(action, $event)"
  (keyup.esc)="onEscHandler($event)">
  <div [appAccessibilityContentOnHoverHandler]
    (contentOnHover)="onMouseOver($event)">
    <app-vi-action-button [sizeClass]="sizeClass"
      #MainButton
      [width]="buttonWidth"
      [action]="action"
      [type]="type"
      [hasOpenerIcon]="hasOpenerIcon"
      [isDisabled]="isDisabled"
      [expanded]="expanded"
      [ariaOwns]="menuId"
      [whiteFocus]="whiteFocus"
      [activeContainer]="activeContainer"
      [fallbackUrl]="fallbackUrl"
      [uid]="id"
      (actionClick)="selectAction(action, $event)"
      [showAccessibilityTooltip]="showAccessibilityTooltip"
      (focusIn)="handleFocusInActionButton()"></app-vi-action-button>
    <app-vi-notification-tooltip *ngIf="hasTooltip && !isDisabled && showTooltip && !expanded"
      (escPressed)="showTooltip = false"
      [addAnimation]="false"
      [direction]="Direction.LEFT"
      [centerArrow]="true"
      [targetElement]="mainButton"
      [notificationText]="generateTooltipText()"></app-vi-notification-tooltip>
  </div>
  <div #dropdownContainer
    *ngIf="actionList?.length && expanded"
    class="dropdown-container {{ sizeClass }}">
    <div #dropdownMenu
      [style.width]="subMenuWidth"
      [style.minWidth]="minDropDownWidth"
      class="dropdown action-menu-items items-{{actionList?.length}}"
      data-stop-propagation="true">
      <div class="sub-dropdown">
        <ul role="menu"
          id="{{menuId}}">
          <li *ngFor="let action of actionList; index as i"
            #menuItems
            tabindex="0"
            role="menuitem"
            (keyup.arrowdown)="handleArrowNav(calcIndex(i+1))"
            (keyup.arrowup)="handleArrowNav(calcIndex(i-1))"
            class="action-menu-item {{ action?.title }}"
            [ngClass]="{
              selected: action.selected,
              unselected: !action.selectable,
              disabled: action.isDisabled,
              separated: action.separated
            }"
            [attr.title]="action.title"
            (appAccessibleClick)="selectSubAction(action)">
            <label data-stop-propagation="true"
              [ngClass]="{
                selected: action.selected
              }">
              <i class="{{ action.icon }}"
                *ngIf="action.icon"></i>
              <span [innerHTML]="action.title"></span>
            </label>
          </li>
          <ng-content select=".dropdown-addon"></ng-content>
        </ul>
      </div>
    </div>
  </div>
</div>