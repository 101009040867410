<section class="signup error-page" id="userAlreadyExistsError">
  <div class="container" data-grid="container">
      <div class="logging-out">
          <div class="e404 api-error-header">
              <h4 role="text">{{resources.UserAlreadyExistsErrorPageHeader}}</h4>
          </div>
          <div class="api-error" role="text">{{resources.UserAlreadyExistsErrorPageText}}</div>
          <div class="api-error" role="text">{{resources.UserAlreadyExistsErrorPageNote}}</div>

          <div class="api-error small-text" role="text">{{resources.UserAlreadyExistsErrorPageTraceId}}</div>
          <div class="api-error">
              <a class="link" role="link" href="{{redirectUrl}}" target="_blank" translate>
                {{resources.UserAlreadyExistsErrorPageBackToPortalLink}}
              </a>
          </div>
          <br>
      </div>
  </div>
</section>
