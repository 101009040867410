<section class="container"
  [ngClass]="{accessible: accessibilityMode}">
  <div class="section">
    <app-vi-action-button [type]="ActionButtonType.ACTION_BUTTON"
      [action]="backAction"
      [width]="'auto'"
      (actionClick)="backBtnClicked()"
      id="back">
    </app-vi-action-button>
    <h2 class="title">{{resources?.UploadGuidelinesTitle}}</h2>
    <div class="sub-title"
      role="heading"
      aria-level="3">{{resources?.UploadGuidelinesSubTitle1}}</div>
    <ul class="list">
      <li>
        <span class="bullet"></span>
        <span class="text">{{resources?.UploadGuidelinesSection1List1}}</span>
      </li>
      <li>
        <span class="bullet"></span>
        <span class="text">{{resources?.UploadGuidelinesSection1List2}}</span>
      </li>
      <li>
        <span class="bullet"></span>
        <span class="text">{{resources?.UploadGuidelinesMaxFilesLimit}}</span>
      </li>
    </ul>
    <a role="link"
      (appAccessibleClick)="openLink(formatsLink)"
      [title]="resources?.UploadGuidelinesLink1"
      [attr.aria-label]="resources?.UploadGuidelinesLink1"
      target="_blank"
      tabindex="0">{{resources?.UploadGuidelinesLink1}}</a>
  </div>
  <div class="section">
    <div class="sub-title"
      role="heading"
      aria-level="3">{{resources?.UploadGuidelinesSubTitle2}}</div>
    <ul class="list">
      <li>
        <span class="bullet"></span>
        <span class="text"
          [innerHtml]="resources?.UploadGuidelinesSection2List1"></span>
      </li>
      <li>
        <span class="bullet"></span>
        <span class="text">{{resources?.UploadGuidelinesSection2List2}}</span>
      </li>
      <li>
        <span class="bullet"></span>
        <span class="text">{{resources?.UploadGuidelinesMaxFilesLimit}}</span>
      </li>
    </ul>
    <a role="link"
      (appAccessibleClick)="openLink(limitationsLink)"
      [title]="resources?.UploadGuidelinesLink2"
      [attr.aria-label]="resources?.UploadGuidelinesLink2"
      target="_blank"
      tabindex="0">{{resources?.UploadGuidelinesLink2}}</a>
  </div>
</section>