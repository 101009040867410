<ng-container>
  <div class="head-line">
    <button (appAccessibleClick)="expanded = !expanded"
      [attr.aria-expanded]="expanded"
      [attr.aria-label]="item.name">
      <i [ngClass]="{
                'i-vi-arrow-up': expanded,
                'i-vi-arrow-down': !expanded
              }"></i>
      <div role="none"
        class="col col-details">
        <span class="col-name">
          <span class="name trimmedTextWithEllipsis"
            [attr.title]="item.name">{{item.name}}</span>
        </span>
        <span class="col-trained">
          <span class="name trimmedTextWithEllipsis">
            <ng-content select="[subTitle]"></ng-content>
          </span>
        </span>
      </div>
    </button>
  </div>
  <div class="content"
    *ngIf="expanded">
    <ng-content select="[content]"></ng-content>
  </div>
</ng-container>
