<div class="button-container"
  id="accountListButtonComponent"
  [ngClass]="{accessible: accessibilityMode,
    'selected': (selected && isSideBarOpened),
    'has-hybrid-badge': hasEdgeExtensions,
    'has-trial-badge': isTrial()}"
  [attr.title]="selectedAccount?.name"
  [attr.tabIndex]="0"
  (appAccessibleClick)="openAccountsList()">
  <div class="name">{{selectedAccount?.name}}</div>
  <span class="{{getBadgeClass()}}"
    [title]="getBadgeText()"
    [attr.aria-label]="getBadgeText()">
    {{ getBadgeText() }}
  </span>
</div>