<div class="user-item"
  tabindex="-1"
  *ngIf="ready">
  <div class="img-wrapper">
    <img [src]="userDetails?.imageUrl || ''"
      role="presentation"
      aria-hidden="true"
      [attr.alt]="resources.HeaderUserImageAlt"
      [appViImageIconFallback]="'i-vi-user'" />
  </div>
  <div class="user-description"
    [title]="getUserTitle()"
    [attr.aria-label]="getUserTitle()"
    role="presentation">
    <div class="user-name truncate"> {{userDetails?.name}}</div>
    <div class="user-provider truncate">{{userDetails?.email}}</div>
  </div>
</div>
