<div class="people-models">
  <vi-ui-alert-message *ngIf="isError"
    [type]="AlertMessageType.WARNING"
    [content]="resources?.PeopleModelsLoadingErrorMessage">
  </vi-ui-alert-message>
  <vi-indexing-face-gate-strip [mode]="FaceGateStripMode.PeopleModels"
    (navigateToCreateAccount)="onNavigateToCreateAccount()"></vi-indexing-face-gate-strip>
  <ng-container *ngIf="!showFaceGateWarning">
    <vi-ui-label [for]="dropdownId"
      [id]="'indexingPeopleModels'"
      [value]="resources?.UploadPeopleModelOption"
      [disabled]="isPeopleModelsDropdownDisable">
    </vi-ui-label>
    <vi-fluent-ui-select [action]="peopleModelAction"
      [actionList]="peopleModelActions"
      [isDisabled]="isPeopleModelsDropdownDisable"
      [placeholder]="resources?.PeopleModelsLoadingPlaceholder"
      [ariaLabelledBy]="'indexingPeopleModels'"
      [(selectedAction)]="selectedPeopleModel"
      (selectedActionChange)="onSelectPeopleModel()">
    </vi-fluent-ui-select>
    <app-vi-action-button [type]="ActionButtonType.ACTION_BUTTON"
      [action]="managePeopleModelsAction"
      [width]="'max-content'"
      [showAccessibilityTooltip]="true"
      [isDisabled]="isDisabled"
      (actionClick)="onManagePeopleModels()">
    </app-vi-action-button>
  </ng-container>
</div>