<div class="vi-notification-card"
  [attr.tabindex]="0">
  <div class="notification-title"
    [ngClass]="{'no-icon': !notification?.icon || !notification?.title}">
    <i *ngIf="notification?.icon && notification?.title"
      class="{{notification?.icon}}"></i>
    <span *ngIf="notification?.title"
      class="notification-title-text">
      {{notification?.title}}
    </span>
    <span class="notification-date">{{getDateString()}}</span>
  </div>
  <div class="notification-message">
    <span class="notification-message-text"
      [innerHtml]="notification?.text"></span>
  </div>
  <div *ngIf="notification?.progress >= 0"
    class="notification-progress-bar">
    <vi-ui-progress-bar [value]="notification?.progress"
      [progressAriaLabel]="progressAriaLabel"></vi-ui-progress-bar>
    <div class="progress-text">{{progressText}}</div>
  </div>
  <div *ngIf="notification?.link"
    role="link"
    class="notification-link">
    <a [title]="notification.link?.text"
      [attr.role]="'link'"
      [attr.aria-label]="notification.link.text"
      (appAccessibleClick)="linkClicked()">{{notification.link.text}}</a>
  </div>
</div>
