<vi-ui-label [value]="resources.Files"></vi-ui-label>
<vi-indexing-file-name-input *ngFor="let file of files; trackBy:fileId"
  [file]="file"
  (nameChanged)="onFileNameChanged($event)">
</vi-indexing-file-name-input>
<p *ngIf="!files.length">
  {{resources.IndexingFileNameEmptyMessage}}
  <vi-ui-link [text]="resources.IndexingFileNameLinkEmptyText"
    [role]="'button'"
    (linkClicked)="onAddFilesClicked()"></vi-ui-link>
</p>