<div class="logo-groups">
  <vi-ui-alert-message *ngIf="!isAdvancedIndexingPresetSelected"
    [type]="AlertMessageType.WARNING_INFO"
    [isContentHtml]="true"
    [content]="resources?.IndexingLogoAdvancedPresetNotSelectedWarningInfo"
    [linkText]="resources?.IndexingLogoAdvancedPresetNotSelectedWarningInfoLink"
    [isButton]="true"
    (buttonClicked)="handleNavigateToIndexingPresetTab()">
  </vi-ui-alert-message>
  <vi-ui-label [for]="dropdownId"
    [id]="'indexingLogosCategories'"
    [value]="resources?.IndexingLogoGroupsLabel"
    [disabled]="isDisabledElement()">
  </vi-ui-label>
  <vi-fluent-ui-select *ngIf="logosGroupActions?.length"
    [action]="logoGroupAction"
    [actionList]="logosGroupActions"
    [ariaLabelledBy]="'indexingLogosCategories'"
    [isDisabled]="isDisabledElement()"
    [(selectedAction)]="selectedLogoGroup"
    (selectedActionChange)="onSelectLogoGroup()">
  </vi-fluent-ui-select>
  <div class="empty-list"
    *ngIf="!isLoading && !logosGroupActions?.length">
    <div>{{resources?.IndexingLogoEmptyLogoGroupsList}}</div>
    <div>{{resources?.IndexingLogoEmptyLogoGroupsListLogoCreationText}}
      <vi-ui-link [href]="LOGO_API_LINK"
        [size]="linkSize"
        [inline]="true"
        [text]="resources?.IndexingLogoEmptyLogoGroupsListLogoCreationLink">
      </vi-ui-link>
    </div>
  </div>
</div>