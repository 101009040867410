<div class="container"
  *ngIf="ready">
  <div class="settings">
    <label class="label"
      id="SettingsAutoPlayLabel"
      for="SettingsAutoPlay">
      {{resources.SettingsAutoPlayTitle}}
    </label>

    <vi-ui-switch id="SettingsAutoPlay"
      [title]="resources.SettingsAutoPlayTitle"
      [(checked)]="autoPlayVideosSettings"
      (checkedChange)="onAutoPlaySettingsChange()">
    </vi-ui-switch>

    <label class="label"
      id="autoCompleteSearchLabel"
      for="autoCompleteSearch">
      {{resources.SettingsAutoCompleteTitle}}
    </label>

    <vi-ui-switch id="autoCompleteSearch"
      [title]="resources.SettingsAutoCompleteTitle"
      [(checked)]="settings.autoCompleteSearch"
      (checkedChange)="settingsChange()">
    </vi-ui-switch>

    <label *ngIf="!isFairFaxEnv"
      class="label"
      id="showSamplesTabLabel"
      for="showSamplesTab">
      {{resources.SettingsSamplesTabLabel}}
    </label>

    <vi-ui-switch *ngIf="!isFairFaxEnv"
      id="showSamplesTab"
      [title]="resources.SettingsSamplesTabLabel"
      [(checked)]="settings.showSamplesTab"
      (checkedChange)="settingsChange()">
    </vi-ui-switch>

    <label class="label"
      id="SettingsShowGalleryByDefaultLabel"
      for="SettingsShowGalleryByDefault">
      {{resources.SettingsShowGalleryByDefaultTitle}}
    </label>

    <vi-ui-switch id="SettingsShowGalleryByDefault"
      [title]="resources.SettingsShowGalleryByDefaultTitle"
      [(checked)]="settings.showGalleryByDefault"
      (checkedChange)="settingsChange()">
    </vi-ui-switch>

    <!-- TODO - hide edge extensions toggle until we have a better solution -->
    <!-- <ng-container *ngIf="showEdgeExtensionsToggle()">
      <label class="label"
        id="SettingsShowArcExtensionsLabel"
        for="SettingsShowArcExtensions">
        {{resources.SettingsShowArcExtensions}}
      </label>

      <vi-ui-switch id="SettingsShowArcExtensions"
        [title]="resources.SettingsShowArcExtensions"
        [(checked)]="showArcExtensions"
        (checkedChange)="onShowArcSettingsChange()">
      </vi-ui-switch>
    </ng-container> -->

    <ng-container>
      <div class="label themes"
        id="themesLabel">
        {{resources.Themes}}
      </div>
      <div class="themes-container"
        role="radiogroup"
        id="themesList"
        aria-labelledby="themesLabel">
        <div class="theme"
          *ngFor="let theme of themes">
          <vi-theme-option (select)="onClickTheme(theme)"
            [theme]="theme"
            [selected]="theme?.selected"
            [accessibilityMode]="accessibilityMode">
          </vi-theme-option>
        </div>
      </div>
    </ng-container>

    <label class="label lang"
      id="LanguageSelectLabel"
      for="languageSelectId">
      {{resources.SettingsLanguageSettingsTitle}}
    </label>

    <vi-fluent-ui-select [id]="languageSelectId"
      [action]="languageFilterAction"
      [selectedAction]="selectedLanguage"
      [actionList]="languagesActions"
      [ariaLabelledBy]="'LanguageSelectLabel'"
      (selectedActionChange)="languageChanged($event)">
    </vi-fluent-ui-select>
  </div>
</div>