<div class="container"
  [ngClass]="{'accessible': accessibilityMode}">
  <div *ngFor="let item of items">
    <div class="menu-item"
      [class]="item.class"
      [ngClass]="{'selected': item.selected}"
      [attr.title]="item.text"
      [attr.aria-label]="item.text"
      tabindex="0"
      (appAccessibleClick)="handleSelectItem(item)">
      {{item.text}}
    </div>
  </div>
</div>