<div id="container"
  [ngClass]="{ 'drag-on': isDragOver, accessible: accessibilityMode }">
  <div #dropZone
    class="drop-zone"
    (dragleave)="onDragLeave($event)"
    (dragover)="onDragOver($event)"
    (drop)="onDrop($event)">
  </div>
  <div class="upload-container">
    <div class="icon-wrapper">
      <svg>
        <path [attr.d]="FILE_UPLOAD_SVG"></path>
      </svg>
    </div>
    <span class="upload-text">
      {{ resources?.UploadDropMediaFiles }}
    </span>
    <span class="upload-or-label hide-on-drag">{{ resources?.UploadOrValue }}</span>
    <div class="select-upload-buttons">
      <app-vi-action-button #chooseFileLink
        [action]="browseForFileAction"
        [type]="ActionButtonType.PRIMARY"
        [width]="buttonSize"
        (actionClick)="uploadChooseFile(fileInput)"
        class="hide-on-drag">
      </app-vi-action-button>
      <app-vi-action-button #enterUrlLink
        [action]="enterFileUrlAction"
        [type]="ActionButtonType.PRIMARY"
        [width]="buttonSize"
        (actionClick)="onButtonClicked(UploadSourceActions.URL)"
        class="hide-on-drag url-action">
      </app-vi-action-button>
    </div>
    <a class="guideline-button link hide-on-drag"
      href="javascript:void(0)"
      role="button"
      appStopPropagation
      [title]="resources?.UploadUploadingGuidelines"
      [attr.aria-label]="resources?.UploadUploadingGuidelines"
      (appAccessibleClick)="onButtonClicked(UploadSourceActions.UploadGuide)"
      tabindex="0">{{ resources?.UploadUploadingGuidelines }}</a>
  </div>
  <input #fileInput
    type="file"
    name="fileInput"
    [accept]="filesType"
    multiple
    (change)="onFileBrowse($event)" />

  <vi-checkbox-with-label class="use-saved-indexing-settings"
    *viShowIfEdge="false"
    [checked]="useSavedIndexingSettings"
    (checkedChange)="onUseSavedIndexingSettingsChange($event)"
    [label]="resources?.UploadUsingSavedIndexingSettingsLabel">
  </vi-checkbox-with-label>
</div>
