<div class="accordion-item-header"
  role="button"
  tabIndex="0"
  [attr.aria-expanded]="opened"
  [attr.aria-label]="title"
  (viUiAccessibleClick)="onToggle()"
  [ngClass]="{'start-position': startPosition, 'end-position': endPosition}">
  <div class="title">
    {{title}}
  </div>
  <i [ngClass]="iconClass">
  </i>
</div>
<ng-container *ngIf="opened">
  <ng-content></ng-content>
</ng-container>