<div class="container">
  <div class="content-container"
    [ngClass]="showAdvancedMode ? 'advanced-mode': 'basic'">
    <aside *ngIf="showAdvancedMode">
      <vi-ui-nav-bar class="side-navbar"
        [items]="settings"
        [selectedItemId]="selectedAdvancedSettings$ | async"
        (itemSelected)="onSelectedSettings($event)">
      </vi-ui-nav-bar>
    </aside>
    <!-- Main View -->
    <main class="main-view">
      <ng-container [ngSwitch]="selectedAdvancedSettings$ | async">
        <ng-container *ngSwitchCase="AdvancedSettings.General">
          <ng-container *ngTemplateOutlet="generalSettings"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="AdvancedSettings.IndexingPreset">
          <ng-container *ngTemplateOutlet="indexingPreset"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="AdvancedSettings.PeopleModels">
          <ng-container *ngTemplateOutlet="peopleModels"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="AdvancedSettings.BrandCategories">
          <ng-container *ngTemplateOutlet="brandCategories"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="AdvancedSettings.LogoGroups">
          <ng-container *ngTemplateOutlet="LogoGroups"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="AdvancedSettings.FileNames">
          <ng-container *ngTemplateOutlet="fileNames"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="AdvancedSettings.FileInfo">
          <ng-container *ngTemplateOutlet="fileInfo"></ng-container>
        </ng-container>
      </ng-container>
    </main>
    <!-- Main View In Mobile-->
    <main class="mobile-view">
      <vi-ui-accordion [mode]="AccordionMode.MULTIPLE">
        <vi-ui-accordion-item [attr.id]="GENERAL_SETTINGS_MOBILE_ID"
          [opened]="isGeneralSettingsMobileOpened"
          (toggle)="isGeneralSettingsMobileOpened = !isGeneralSettingsMobileOpened"
          [title]="resources?.IndexingGeneralSettings">
          <div class="mobile-content">
            <ng-container *ngTemplateOutlet="generalSettings">
            </ng-container>
          </div>
        </vi-ui-accordion-item>
        <vi-ui-accordion-item *ngIf="showFileNamesSection"
          [title]="resources?.IndexingFileNamesSettings">
          <div class="mobile-content">
            <ng-container *ngTemplateOutlet="fileNames">
            </ng-container>
          </div>
        </vi-ui-accordion-item>
        <vi-ui-accordion-item [title]="resources?.IndexingIndexingPresetsSettings">
          <div class="mobile-content">
            <ng-container *ngTemplateOutlet="indexingPreset">
            </ng-container>
          </div>
        </vi-ui-accordion-item>
        <vi-ui-accordion-item [title]="resources?.IndexingPeopleModelsSettings">
          <div class="mobile-content">
            <ng-container *ngTemplateOutlet="peopleModels">
            </ng-container>
          </div>
        </vi-ui-accordion-item>
        <vi-ui-accordion-item [title]="resources?.IndexingBrandCategoriesSettings">
          <div class="mobile-content">
            <ng-container *ngTemplateOutlet="brandCategories">
            </ng-container>
          </div>
        </vi-ui-accordion-item>
        <vi-ui-accordion-item [title]="resources?.IndexingLogoGroupsSettings">
          <div class="mobile-content">
            <ng-container *ngTemplateOutlet="LogoGroups">
            </ng-container>
          </div>
        </vi-ui-accordion-item>
        <vi-ui-accordion-item [title]="fileInfoTitle">
          <div class="mobile-content">
            <ng-container *ngTemplateOutlet="fileInfo">
            </ng-container>
          </div>
        </vi-ui-accordion-item>
      </vi-ui-accordion>
    </main>
  </div>
  <ng-container *ngIf="enableBasicEdgePreset || !hasSelectedEdgeExtension">
    <app-vi-action-button *ngIf="showAdvancedMode"
      class="back-to-basic"
      [type]="ActionButtonType.ACTION_BUTTON"
      [action]="backToBasicAction"
      [width]="'auto'"
      [isDisabled]="isDisabled"
      (actionClick)="onBackToBasicSettings()"
      id="backToBasic">
    </app-vi-action-button>
    <app-vi-action-button *ngIf="!showAdvancedMode"
      class="advanced-settings"
      [type]="ActionButtonType.ACTION_BUTTON"
      [action]="advancedSettingsAction"
      [width]="'auto'"
      [isDisabled]="isDisabled"
      (actionClick)="onAdvancedSettingsClicked()"
      id="advancedSettings">
    </app-vi-action-button>
  </ng-container>
</div>

<!-- All Indexing Options Templates-->
<ng-template #generalSettings>
  <vi-indexing-settings-general [isDisabled]="isDisabled"
    [isMultiFileMode]="isMultiFileMode"
    (advancedSettingsClick)="onAdvancedSettingsClicked()"
    (managedLanguageModelsClick)="onManageModelsClicked($event)"
    (managedSpeechModelsClick)="onManageModelsClicked($event)"
    (languageIdLinkClick)="onManageModelsClicked($event)">
  </vi-indexing-settings-general>
</ng-template>

<ng-template #indexingPreset>
  <vi-indexing-presets [excludeRAI]="excludeRAI$ | async"
    [indexingPreset]="indexingPreset$ | async"
    [isDisabled]="isDisabled"
    (excludeRAIChange)="onExcludedRAIChange($event)"
    (excludeAIsChange)="onExcludeAIsChanged($event)"
    (presetChange)="onIndexingPresetChanged($event)">
  </vi-indexing-presets>
</ng-template>

<ng-template #peopleModels>
  <vi-indexing-settings-people-models [isDisabled]="isDisabled"
    (navigateToCreateAccount)="onNavigateToCreateAccount()"
    (managePeopleModelClicked)="onManageModelsClicked($event)">
  </vi-indexing-settings-people-models>
</ng-template>

<ng-template #brandCategories>
  <vi-indexing-settings-brand-categories [isDisabled]="isDisabled"
    (manageBrandsClicked)="onManageModelsClicked($event)">
  </vi-indexing-settings-brand-categories>
</ng-template>

<ng-template #LogoGroups>
  <vi-indexing-settings-logo-groups [isDisabled]="isDisabled"
    [isAdvancedIndexingPresetSelected]="isAdvancedIndexingPresetSelected"
    (manageLogosClicked)="onManageModelsClicked($event)">
  </vi-indexing-settings-logo-groups>
</ng-template>

<ng-template #fileNames>
  <vi-indexing-file-names (addFilesClicked)="onAddFilesClicked()">
  </vi-indexing-file-names>
</ng-template>

<ng-template #fileInfo>
  <vi-indexing-file-information [isDisabled]="fileInfoDisabled">
  </vi-indexing-file-information>
</ng-template>