<section class="alert-message"
  [ngClass]="type">
  <div class="alert-icon"
    role="none"
    aria-hidden="true">
    <i *ngIf="type === 'error'"
      class="i-vi-error"></i>
    <i *ngIf="type === 'warning'"
      class="i-vi-warning"></i>
    <i *ngIf="type === 'info' || type === 'warning-info'"
      class="i-vi-info"></i>
  </div>

  <div class="alert-content">
    <span *ngIf="!isContentHtml"
      class="alert-text"
      role="alert"
      [title]="contentTitle || content"
      [attr.aria-label]="contentTitle || content">
      {{ content }}
    </span>
    <span *ngIf="isContentHtml"
      class="alert-text"
      role="alert"
      [title]="contentTitle || content"
      [attr.aria-label]="contentTitle || content"
      [innerHTML]="content">
    </span>
    <vi-ui-link *ngIf="linkText"
      class="alert-link"
      [inline]="true"
      [size]="'12-16'"
      [highContrast]="true"
      [role]="isButton? 'button': 'link'"
      [href]="isButton? 'javascript:void(0)' : linkPath"
      [text]="linkText"
      (linkClicked)="onClick()"></vi-ui-link>
  </div>
</section>
