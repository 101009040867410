<app-vi-strip *ngFor="let strip of notificationsStrips"
  class="strip"
  [text]="strip?.text"
  [iconClass]="strip?.iconClass"
  [action]="strip?.action"
  [hasSpinner]="strip?.hasSpinner"
  [hasCloseButton]="strip?.hasCloseButton"
  [messageType]="strip?.messageType"
  [truncate]="false"
  (actionClick)="actionClicked($event, strip)"
  (linkClick)="linkClicked($event, strip)"
  (closeClick)="closeNotificationStrip($event, strip?.id)">
</app-vi-strip>
<app-vi-strip *ngFor="let strip of strips"
  class="strip"
  [text]="strip?.text"
  [iconClass]="strip?.iconClass"
  [action]="strip?.action"
  [hasSpinner]="strip?.hasSpinner"
  [hasCloseButton]="strip?.hasCloseButton"
  [messageType]="strip?.messageType"
  [truncate]="false"
  [progress]="isProgressNumber(strip?.loadingProgress)"
  [loadingProgress]="strip?.loadingProgress"
  (linkClick)="linkClicked($event, strip)"
  (closeClick)="closeStrip($event, strip?.id)">
</app-vi-strip>
