<section class="searchbar"
  [ngClass]="{'disabled': isDisabled, 'no-status': !status}">
  <div class="col searchinput">
    <div class="ms-TextField-wrapper">
      <div class="ms-TextField-fieldGroup">
        <i [ngClass]="{'i-vi-search': !searching, 'i-vi-close-small': searching }"
          aria-hidden="true"></i>
        <input type="text"
          id="searchbar_{{id}}"
          [value]="initialSearchValue ?? ''"
          autocomplete="off"
          placeholder="{{placeholder}}"
          [title]="resources?.CognitiveInsightsSearch"
          [attr.disabled]="isDisabled ? '' : null"
          (focus)="handleFocus(true)"
          (focusout)="handleFocus(false)"
          class="ms-TextField-field x-hidden-focus"
          aria-invalid="false">
      </div>
    </div>
  </div>
  <div class="col statusbar"
    *ngIf="status"
    [appAccessibilityAnnouncer]="status">
    <div class="status"
      [attr.role]="isStatusClickable ? 'button':null"
      [attr.tabindex]="isStatusClickable ? '0':'-1'"
      (appAccessibleClick)="statusClicked()"
      [ngClass]="{'is-clickable':isStatusClickable}">
      {{ status }}
    </div>
  </div>
</section>
