<div class="notifications-panel"
  *ngIf="(alerts?.length > 0 || messages?.length > 0) && !notificationsLoading">
  <ng-container *ngIf="alerts?.length > 0">
    <div class="head-wrapper">
      <span class="section-title">{{resources?.SidebarUserAlerts}}</span>
      <app-vi-action-button [action]="clearAlertsNotifications"
        [type]="ActionButtonType.PRIMARY_TEXT"
        (appAccessibleClick)="clearAlertsAndBannersNotificationsClicked()">
      </app-vi-action-button>
    </div>
    <div class="alerts-container">
      <ul id="alertsList"
        role="list"
        [attr.aria-label]="resources?.SidebarUserAlerts">
        <li *ngFor="let notification of alerts"
          role="listitem">
          <vi-notification-card [notification]="notification"></vi-notification-card>
        </li>
      </ul>
    </div>
  </ng-container>
  <ng-container *ngIf="messages?.length > 0">
    <div class="head-wrapper messages">
      <span class="section-title">{{resources?.SidebarUserMessages}}</span>
      <app-vi-action-button [action]="clearMessagesNotifications"
        [type]="ActionButtonType.PRIMARY_TEXT"
        (appAccessibleClick)="clearMessagesNotificationsClicked()">
      </app-vi-action-button>
    </div>
    <div class="messages-container">
      <ul id="messagesList"
        role="list"
        [attr.aria-hidden]="false"
        [attr.aria-label]="resources?.SidebarUserMessages">
        <li *ngFor="let notification of messages"
          role="listitem">
          <vi-notification-card [notification]="notification"></vi-notification-card>
        </li>
      </ul>
    </div>
  </ng-container>
</div>
<div class="empty-state-wrapper"
  *ngIf="alerts?.length === 0 && messages?.length === 0 && !notificationsLoading">
  <div class="icon-wrapper">
    <svg>
      <circle cx="50"
        cy="50"
        r="50" />
      <path [attr.d]="NOTIFICATIONS_EMPTY_STATE_SVG_PATH2"></path>
      <path [attr.d]="NOTIFICATIONS_EMPTY_STATE_SVG_PATH1"></path>
    </svg>
  </div>
  <div class="empty-title">{{resources?.SidebarNotificationsEmptyTitle}}</div>
  <div class="empty-subtitle">{{resources?.SidebarNotificationsEmptySubtitle}}</div>
</div>
<div class="loading-wrapper"
  *ngIf="notificationsLoading">
  <app-vi-spinner></app-vi-spinner>
</div>
