<div [ngClass]="{accessible: accessibilityMode}">
  <vi-ui-label class="label-form"
    [disabled]="isDisabled || isLanguageLoading"
    [value]="resources?.UploadVideoSourceLanguage"
    [for]="indexingLanguageId"
    [id]="indexingLanguageLabelId"></vi-ui-label>
  <vi-fluent-ui-select [action]="languageAction"
    [id]="indexingLanguageId"
    [(selectedAction)]="selectedLanguageAction"
    [actionList]="languageOptions"
    [isDisabled]="isDisabled || isLanguageLoading"
    [ariaLabelledBy]="indexingLanguageLabelId"
    (selectedActionChange)="onSelectLang($event)">
  </vi-fluent-ui-select>
  <ng-container *viShowIfEdge="false">
    <ng-container *ngIf="showAutoMultiLanguageLink">
      <div class="link"
        *ngIf="!isAutoDetectLanguageEnabled; else AutoMultiSelectedLanguages">
        <vi-ui-link class='multi'
          [href]="multiLanguageLink"
          [title]="resources?.UploadMultiLanguagePreviewFeature"
          [role]="'link'"
          [text]="resources?.UploadMultiLanguagePreviewFeature">
        </vi-ui-link>
      </div>
      <ng-template #AutoMultiSelectedLanguages>
        <div class="multi-languages-selected-text"
          [innerHTML]="safeTextAutoMultiLanguageSelectedLanguagesIds">
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
  <div *viShowIfEdge="false"
    class="manage-buttons">
    <vi-ui-link [title]="resources?.VIUploadCustomizationApplied"
      [role]="'button'"
      [inline]="true"
      (linkClicked)="onManageLanguageClicked()"
      [text]="resources?.VIUploadCustomizationApplied"
      [target]="''"
      [size]="'13-18'">
    </vi-ui-link>
    <span class="or-value">{{ resources?.UploadOrValue }}</span>
    <vi-ui-link [title]="resources?.IndexingManageSpeechModels"
      [role]="'button'"
      [inline]="true"
      (linkClicked)="onSpeechModelsClicked()"
      [text]="resources?.IndexingManageSpeechModels"
      [target]="''"
      [size]="'13-18'">
    </vi-ui-link>
  </div>
</div>