<div class="content-container" [ngSwitch]="state">
  <div *ngSwitchCase="DeleteState.FORM" class="delete-form">
    <!--Message-->
    <label class="message">
      {{ isUserOwner? resources?.DeleteAccountPermanentlyDeletedMessage : resources?.DeleteAccountNoLongerAccessMessage }}
    </label>
    <!--Contributors-->
    <div *ngIf="isUserOwner">
      <div class="contributors-message" *ngIf="account.contributors.length">
        {{ resources?.DeleteAccountSharedPeopleTitle }}
        <br>
        <ul class="contributors-list">
          <li class="contributor" *ngFor="let cont of account.contributors">
              <app-vi-persona
              id="email"
              [personaId]="cont.id"
              [label]="cont.name"
            >
            </app-vi-persona>
            <span>{{ cont.name }}</span>
             &nbsp;
            <span class="email">({{ cont.email }})</span>
          </li>
        </ul>
      </div>
      <div class="contributors-message" *ngIf="!account.contributors.length">
        {{ resources?.DeleteAccountEmptyAccountSharedList }}
      </div>
    </div>
    <!--Enter account Name-->
    <div class="account-name-container">
      <label for="accountName">{{ resources?.DeleteAccountEnterNameAccountLabel}}</label>
      <input
        id="accountName"
        type="text"
        tabindex="0"
        [title]="resources?.DeleteAccountEnterNameAccountTitle"
        [placeholder]="resources?.DeleteAccountEnterNameAccountPlaceholder"
        [(ngModel)]="accountNameInput"
        appDebounce
        [debounceValue]="accountNameInput"
        [debounceEventNames] = "['input']"
        (debounceEvent)= "validateAccountName()"
        (keyup.enter)="deleteAccount()"
      >
    </div>
    <!--Buttons-->
    <div class="buttons-container">
        <app-vi-action-button
          *ngIf="deleteAccountAction && deleteAccountAction.title"
          [action]="deleteAccountAction"
          [isDisabled]="deleteAccountAction.isDisabled"
          [type]="ActionButtonType.PRIMARY"
          [width]="BUTTON_WIDTH"
          (actionClick)="deleteAccount()">
        </app-vi-action-button>
        <app-vi-action-button
          [action]="cancelAction"
          [isDisabled]="cancelAction.isDisabled"
          [type]="ActionButtonType.SECONDARY"
          [width]="BUTTON_WIDTH"
          (actionClick)="closeDialog(false)">
        </app-vi-action-button>
    </div>
  </div>
  <div *ngSwitchCase="DeleteState.PROCESS" class="process-container">
    <app-vi-spinner></app-vi-spinner>
    <label>
      {{isUserOwner ? resources?.DeleteAccountDeletedAccountName : resources?.SettingsLeaveAccountPending}}
    </label>
  </div>
  <div *ngSwitchCase="DeleteState.COMPLETED" class="completed-container">
      <label>
        {{ isUserOwner ? resources?.DeleteAccountPending : resources?.DeleteAccountPulledOutSuccessfully}}
      </label>
  </div>
</div>
