<div class="c-checkbox"
  [ngClass]="{accessible: accessibilityMode}">
  <label class="c-label"
    for="{{id}}">
    <input id="{{id}}"
      tabindex="0"
      type="checkbox"
      [ngModel]="checked"
      (change)="toggleCheckbox()"
      [disabled]="disabled || null"
      [attr.aria-required]="true"
      [attr.aria-checked]="checked"
      [attr.aria-label]="ariaLabel ?? label"
      [attr.title]="ariaLabel ?? label" />
    <span class="checkbox-text size-{{labelSize}} {{size}}"
      [attr.disabled]="disabled || null"
      [ngClass]="{disabled: disabled}"
      role="none"
      [innerHTML]="label">
    </span>
  </label>
</div>