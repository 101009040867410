<p class="delete-video-message">{{resources.GalleryDeleteVideoMassage}}</p>
<div class="buttons-wrapper" id="uploadButtons">
    <!-- delete button -->
    <app-vi-action-button
      id="deleteButton"
      [action]="deleteButtonAction"
      [type]="ActionButtonType.PRIMARY"
      [width]="BUTTONS_WIDTH"
      (actionClick)="deleteIndex()">
    </app-vi-action-button>

    <!-- cancel button -->
    <app-vi-action-button
      id="cancelButton"
      [action]="cancelButtonAction"
      [type]="ActionButtonType.SECONDARY"
      [width]="BUTTONS_WIDTH"
      (actionClick)="cancel()">
    </app-vi-action-button>
  </div>
