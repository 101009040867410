<div class="title">{{summaryTitle}}</div>
<div class="summary">
  <div class="details">
    <div class="row">
      <vi-ui-label [value]="filesLabel + ':'"></vi-ui-label>
      <div>
        <span>{{filesNames}}</span>
      </div>
    </div>
    <vi-indexing-summary [rowValueColor]="RowValueColor.Primary"></vi-indexing-summary>
  </div>
</div>
<footer>
  <vi-upload-consent *ngIf="showConsent"
    [isUSAccount]="isUSAccount$ | async"
    [consent]="userConsent"
    [isTrialAccount]="isTrialAccount$ | async"
    (consentChange)="onConsentChanged($event)">
  </vi-upload-consent>
</footer>