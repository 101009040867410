<app-actions-menu *ngIf="ready"
  class="download-menu"
  [id]="id"
  [calcWidth]="false"
  [action]="downloadAction"
  [hasOpenerIcon]="true"
  [actionList]="downloadOptions"
  [sizeClass]="alignTo"
  [title]="resources?.Download"
  [updateDropdownLocation]="true"
  (actionChange)="selectAction($event)">
</app-actions-menu>
