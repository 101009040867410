<div class="vi-input">
  <vi-ui-label *ngIf="label"
    [for]="id"
    [disabled]="disabled"
    [required]="required"
    [value]="label">
  </vi-ui-label>
  <input [type]="type"
    [id]="id"
    [ngClass]="{error: error, 'with-icon': icon}"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [attr.maxlength]="maxLength"
    [(ngModel)]="value"
    (input)="inputChanged.emit($event.target.value)">
  <i *ngIf="icon"
    class="i-vi-{{icon.type}}"></i>
  <span class="error-message"
    *ngIf="error"
    aria-live="assertive">
    {{ errorMessage }}
  </span>
</div>