<!-- TODO (code removal - indexing preset old UI) -->
<section *ngIf="!isExcludeAIsEnabled else excludeAIs">
  <vi-ui-label [id]="dropdownLabelId"
    [for]="dropdownId"
    [disabled]="isDisabled"
    [value]="resources.IndexingPresetLabel"></vi-ui-label>
  <span class="info-container">
    <i #Info
      class="i-vi-info"
      [attr.tabindex]="0"
      (focus)="togglePresetTooltip(true)"
      (blur)="togglePresetTooltip(false)"
      [appAccessibilityContentOnHoverHandler]
      (contentOnHover)="togglePresetTooltip($event)"></i>
    <app-vi-notification-tooltip *ngIf="showPresetTooltip"
      [targetElement]="info"
      [centerArrow]="true"
      [addAnimation]="false"
      [notificationText]="resources.IndexingPresetTooltipText">
    </app-vi-notification-tooltip>
  </span>

  <vi-fluent-ui-select [action]="presetsAction"
    [actionList]="presets"
    [(selectedAction)]="selectedPreset"
    [group]="true"
    [isDisabled]="isDisabled"
    [ariaLabelledBy]="dropdownLabelId"
    (selectedActionChange)="selectPreset($event)">
  </vi-fluent-ui-select>

  <label class="preset-details">{{ resources.IndexingPresetDetailsLabel }}</label>
  <p [innerHTML]="selectedPreset?.label"></p>
  <vi-ui-link [href]="indexingPresetsLink"
    [size]="linkSize"
    [text]="resources.IndexingPresetLearnMoreLinkText">
  </vi-ui-link>
  <ng-container *viShowIfEdge="false">
    <div class="rai-container">
      <vi-checkbox-with-label [checked]="excludeRAI"
        [disabled]="disableExcludeRAI"
        [label]="resources.IndexingPresetExcludeRAICheckboxLabel"
        [appAccessibilityContentOnHoverHandler]
        (contentOnHover)="onHoverRAICheckbox($event)"
        (checkedChange)="toggleRAI($event)">
      </vi-checkbox-with-label>
      <app-vi-notification-tooltip *ngIf="showCantExcludeRAITooltip"
        [showArrow]="false"
        [addAnimation]="false"
        [notificationText]="excludeRAITooltipText">
      </app-vi-notification-tooltip>
    </div>
    <p [ngClass]="{disabled: disableExcludeRAI}"
      [attr.disabled]="disableExcludeRAI">
      {{ resources.IndexingPresetExcludeRAIDescription }}
      <vi-ui-link [href]="RAILink"
        [size]="linkSize"
        [inline]="true"
        [text]="resources.IndexingPresetRAILinkText">
      </vi-ui-link>
    </p>
  </ng-container>
</section>
<!-- TODO (end code removal) -->

<!-- Exclude AIs is enabled -->
<ng-template #excludeAIs>
  <section class="exclude-ais">

    <!-- Preset dropdown -->
    <vi-ui-label [id]="dropdownLabelId"
      [for]="dropdownId"
      [disabled]="isDisabled"
      [value]="resources.IndexingPresetLabel"></vi-ui-label>
    <span class="info-container">
      <i #Info
        class="i-vi-info"
        [attr.tabindex]="0"
        (focus)="togglePresetTooltip(true)"
        (blur)="togglePresetTooltip(false)"
        [appAccessibilityContentOnHoverHandler]
        (contentOnHover)="togglePresetTooltip($event)"></i>
      <app-vi-notification-tooltip *ngIf="showPresetTooltip"
        [targetElement]="info"
        [centerArrow]="true"
        [addAnimation]="false"
        [notificationText]="resources.IndexingPresetTooltipText">
      </app-vi-notification-tooltip>
    </span>
    <vi-fluent-ui-select [action]="presetsAction"
      [actionList]="presets"
      [(selectedAction)]="selectedPreset"
      [group]="true"
      [isDisabled]="isDisabled"
      [ariaLabelledBy]="dropdownLabelId"
      (selectedActionChange)="selectPreset($event)">
    </vi-fluent-ui-select>

    <!-- Description -->
    <vi-ui-label [value]="resources?.IndexingPresetIncludedModelsLabel"></vi-ui-label>
    <p [innerHTML]="resources?.IndexingPresetIncludedModelsDescription"></p>
    <vi-ui-link [href]="indexingPresetsLink"
      [size]="linkSize"
      [inline]="true"
      [text]="resources?.IndexingPresetLearnMoreModelsLinkText">
    </vi-ui-link>



    <div class="checkbox-container">
      <ul *ngIf="(isSupportedAIsLoaded$ | async) === false; else AIsLoaded"
        class="models items-0 loading">
        <vi-ui-shimmer [elementType]="ShimmerElementType.CHECKBOX"
          [numOfElements]="LOADING_SHIMMER_ELEMENTS">
        </vi-ui-shimmer>
      </ul>
      <ng-template #AIsLoaded>
        <div class="error-container"
          *ngIf="isLoadSupportedAIsError; else ais">
          <i class="i-vi-illustration-error error"></i>
          <vi-empty-state [title]="resources?.SupportedAIsFailedTitle"
            [content]="resources?.SupportedAIsFailedContent"
            [shouldShowIcon]="false">
          </vi-empty-state>
        </div>
        <ng-template #ais>
          <ul class="models items-{{supportedAIs.length}}">
            <li *ngFor="let ai of supportedAIs; let i = index">
              <vi-checkbox-with-label #AICheckbox
                [checked]="supportedAIsMap[ai]?.checked"
                [disabled]="supportedAIsMap[ai]?.disabled"
                [label]="supportedAIsMap[ai]?.displayName"
                [appAccessibilityContentOnHoverHandler]
                [size]="checkboxSize"
                (contentOnHover)="onHoverSupportedAICheckbox($event, ai)"
                (checkedChange)="onExcludeAI($event, ai)">
              </vi-checkbox-with-label>
              <ng-container [ngTemplateOutlet]='supportedAIsMap[ai]?.info ? aiInfo : null'
                [ngTemplateOutletContext]="{ai:supportedAIsMap[ai]}"></ng-container>
              <ng-container [ngTemplateOutlet]='supportedAIsMap[ai]?.tooltip ? sensitiveAITooltip : null'
                [ngTemplateOutletContext]="{ai:supportedAIsMap[ai], index:i}"></ng-container>
            </li>
          </ul>
        </ng-template>
      </ng-template>
    </div>


    <!-- Sensitive AI models -->
    <ng-container *viShowIfEdge="false">
      <ng-container *ngIf="!hideExcludeRAI">
        <vi-ui-label [value]="resources?.IndexingPresetExcludeRAITitle"></vi-ui-label>
        <div class="exclude-ais-rai-container">
          <vi-ui-switch id="excludeSensiativeAIs"
            [title]="resources?.IndexingPresetExcludeRAIToggleLabel"
            [(checked)]="excludeRAI"
            [debounceDelay]="false"
            (checkedChange)="toggleRAI($event)">
          </vi-ui-switch>
          <label id="excludeSensiativeAIsLabel"
            for="excludeSensiativeAIs">
            {{ resources?.IndexingPresetExcludeRAIToggleLabel }}
          </label>
        </div>
        <p>
          {{ resources?.IndexingPresetExcludeSensitiveModelsDescription }}
        </p>
        <vi-ui-link [href]="RAILink"
          [inline]="true"
          [size]="linkSize"
          [text]="resources.IndexingPresetRAILinkText">
        </vi-ui-link>
      </ng-container>
    </ng-container>
  </section>
</ng-template>

<ng-template #aiInfo
  let-ai="ai">
  <span class="ai-info-container">
    <i #ModelInfo
      class="i-vi-info"
      [attr.tabindex]="0"
      (focus)="toggleAIInfoTooltip(true, ai.name)"
      (blur)="toggleAIInfoTooltip(false, ai.name)"
      [appAccessibilityContentOnHoverHandler]
      (contentOnHover)="toggleAIInfoTooltip($event, ai.name)"></i>
    <app-vi-notification-tooltip *ngIf="shouldShowAIInfoTooltip(ai.name)"
      [targetElement]="modelInfo"
      [centerArrow]="true"
      [addAnimation]="false"
      [notificationText]="ai.info">
    </app-vi-notification-tooltip>
  </span>
</ng-template>

<ng-template #sensitiveAITooltip
  let-ai="ai"
  let-index="index">
  <app-vi-notification-tooltip *ngIf="ai.tooltip?.show(ai.name)"
    [targetElement]="aIsCheckbox.get(index)"
    [centerArrow]="true"
    [addAnimation]="false"
    [notificationText]="ai.tooltip?.text">
  </app-vi-notification-tooltip>
</ng-template>