<div class="actions-menu-container">
  <div>
    <app-vi-action-button [action]="placeholderAction"
      [hasOpenerIcon]="true"
      [type]="type"
      [isDisabled]="isDisabled"
      [expanded]="expanded"
      [ariaLabelledBy]="ariaLabelledBy"
      [width]="'100%'"
      (actionClick)="toggleDropdown()">
    </app-vi-action-button>
  </div>
  <ng-container *ngIf="expanded">
    <div class="sub-dropdown {{size}}">
      <ul class="presets-insights-list"
        id="indexing-brand"
        name="indexing-brand">
        <li *ngFor="let ins of actions; index as i"
          data-stop-propagation="true"
          [ngClass]="{ 'main-action': ins.type === UIActionType.GROUP, 'sub-action': ins.group, 'selected': ins.selected }">
          <div class="c-checkbox">
            <label class="c-label"
              data-stop-propagation="true"
              for="checkboxId{{ ins.id }}_action_{{i}}">
              <input data-stop-propagation="true"
                (change)="handleChange(ins)"
                type="checkbox"
                id="checkboxId{{ ins.id }}_action_{{i}}"
                name="checkboxId{{ ins.id }}_action"
                [(ngModel)]="ins.selected"
                role="checkbox"
                [attr.aria-disabled]="ins.disabled"
                [attr.disabled]="ins.disabled ? '' : null"
                [attr.aria-checked]="ins.selected"
                [attr.aria-label]="ins.title" />
              <span aria-hidden="true"
                [attr.disabled]="ins.disabled ? '' : null"
                data-stop-propagation="true"
                [title]="ins.title"
                [attr.aria-label]="ins.title">
                {{ ins.title }}
              </span>
            </label>
          </div>
        </li>
      </ul>
    </div>
  </ng-container>
</div>