<!-- Dialog header -->
<div class="dialog-title-container">
  <div class="main-title">
    <!--Header with title and close icon-->
    <h1>
      {{resources?.UploadDialogTitle}}
    </h1>
    <app-vi-action-button *ngIf="!showConfirmExit"
      class="close-dialog"
      [action]="closeFromHeaderAction"
      [showAccessibilityTooltip]="true"
      (actionClick)="confirmClose()">
    </app-vi-action-button>
  </div>
</div>
<ng-container *ngIf="!showConfirmExit; else confirmExitScreen">
  <ng-container [ngSwitch]="displayedUploadPage">
    <vi-upload-source *ngSwitchCase="UploadPages.UploadSource"
      (filesSelected)="onFilesSelected($event)"
      (buttonClicked)="onUploadSourceButtonClicked($event)"
      (useSavedIndexingSettingsChanged)="onUseSavedIndexingSettingsChanged($event)"
      [filesType]="filesType"></vi-upload-source>
    <vi-upload-guide *ngSwitchCase="UploadPages.UploadGuide"
      (backClicked)="switchPage(UploadPages.UploadSource)"></vi-upload-guide>
    <div class="indexing-form-container"
      *ngSwitchCase="UploadPages.IndexingForm">
      <div class="strips-container">
        <vi-indexing-face-gate-strip class="strip"
          [mode]="FaceGateStripMode.Indexing"
          (navigateToCreateAccount)="onNavigateToCreateAccount()"></vi-indexing-face-gate-strip>
        <vi-ui-alert-message class="strip"
          *ngIf="showUploadFileError"
          [type]="AlertMessageType.WARNING"
          [content]="resources?.UploadInvalidFilesMessage"
          isContentHtml="true"
          (contentLinkClicked)="onOpenUploadGuidelineLink()"></vi-ui-alert-message>
      </div>
      <vi-indexing-form (filesSelected)="onFilesSelected($event)"
        [filesType]="filesType"
        (navigateToCreateAccount)="onNavigateToCreateAccount()"
        (manageModelsClicked)="confirmClose($event)"></vi-indexing-form>
    </div>
    <div *ngSwitchCase="UploadPages.UploadReview">
      <vi-indexing-review-page></vi-indexing-review-page>
    </div>
    <vi-upload-summary-page *ngSwitchCase="UploadPages.UploadSummary"
      (createAccountClicked)="onNavigateToCreateAccount()">
    </vi-upload-summary-page>
  </ng-container>
  <footer *ngIf="showFooter">
    <!-- upload consent -->
    <vi-upload-consent *ngIf="!isExcludeAIsEnable"
      [isUSAccount]="isUSAccount$ | async"
      [consent]="userConsent"
      [disabled]="displayedUploadPage === UploadPages.UploadSummary"
      [isTrialAccount]="isTrialAccount$ | async"
      (consentChange)="onConsentChanged($event)"></vi-upload-consent>

    <div class="buttons-container">
      <!-- back to settings button (visible on UPLOAD REVIEW PAGE) -->
      <app-vi-action-button *ngIf="showBackToSettingButton"
        class="back-to-settings-button"
        [action]="backToSettingsACtion"
        [type]="ActionButtonType.SECONDARY"
        [minWidth]="BACK_TO_SETTINGS_BUTTON_MIN_WIDTH"
        (actionClick)="onBackToSettingsClicked()">
      </app-vi-action-button>

      <!-- upload button (visible on UPLOAD REVIEW PAGE when excludeAIs is enabled or on INDEXING FORM when excludeAIs is disabled) -->
      <app-vi-action-button *ngIf="showUploadButton"
        class="upload-button"
        [action]="uploadAndIndexAction"
        [isDisabled]="isUploadDisabled"
        [type]="ActionButtonType.PRIMARY"
        [minWidth]="UPLOAD_BUTTON_MIN_WIDTH"
        (actionClick)="uploadClicked()">
      </app-vi-action-button>

      <!-- review and upload button (visible on INDEXING FORM when excludeAIs is enabled)-->
      <app-vi-action-button *ngIf="showReviewAndUploadButton"
        [action]="reviewAndUploadAction"
        [isDisabled]="isReviewAndUploadDisabled"
        [type]="ActionButtonType.PRIMARY"
        [minWidth]="UPLOAD_BUTTON_MIN_WIDTH"
        (actionClick)="onReviewAndUploadClicked()">
      </app-vi-action-button>

      <!-- run in back button -->
      <app-vi-action-button *ngIf="isUploadInProgress"
        class="run-in-back-button"
        [action]="runInBackAction"
        [type]="ActionButtonType.SECONDARY"
        [minWidth]="RUN_IN_BACK_BUTTON_MIN_WIDTH"
        (actionClick)="confirmClose()">
      </app-vi-action-button>

      <!-- close button -->
      <app-vi-action-button *ngIf="isUploadingDone"
        class="close-button"
        [action]="closeAction"
        [minWidth]="CLOSE_BUTTON_MIN_WIDTH"
        [type]="ActionButtonType.SECONDARY"
        (actionClick)="confirmClose()">
      </app-vi-action-button>

      <!-- cancel button -->
      <app-vi-action-button *ngIf="!isUploadInProgress && !isUploadingDone"
        class="cancel-button"
        [action]="cancelAction"
        [type]="ActionButtonType.SECONDARY"
        [minWidth]="CANCEL_BUTTON_MIN_WIDTH"
        (actionClick)="confirmClose()">
      </app-vi-action-button>
    </div>
  </footer>
</ng-container>

<ng-template #confirmExitScreen>
  <vi-confirm-exit-indexing (backToIndexing)="onBackToIndexing()"
    (leaveIndexing)="leaveIndexing()">
  </vi-confirm-exit-indexing>
</ng-template>
