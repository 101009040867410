<div class="vi-textarea">
  <vi-ui-label *ngIf="label"
    [for]="name"
    [disabled]="disabled"
    [value]="label"></vi-ui-label>
  <div *ngIf="!error && (description || counter)"
    class="textarea-description">
    <span *ngIf="description">{{description}}</span>
    <span *ngIf="counter">{{counter}}</span>
  </div>
  <textarea [attr.name]="name"
    [attr.id]="name"
    [ngClass]="{error: error}"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [(ngModel)]="value"
    [maxlength]="maxLength"
    [ngStyle]="{height: height || DEFAULT_HEIGHT}"
    (input)="inputChanged.emit($event.target.value)"
    (focusout)="onFocusedOut($event.target.value)"></textarea>
  <span class="
    error-message"
    *ngIf="error">
    {{ errorMessage }}
  </span>
</div>