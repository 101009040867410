<div class="help-content">
  <ng-container *ngTemplateOutlet="listTemplate;context:{list: this.helpList}">
  </ng-container>

  <div class="sub-title"
    role="heading"
    aria-level="3">{{resources.HelpReferences}}
  </div>

  <ng-container *ngTemplateOutlet="listTemplate;context:{list: this.referencesList}">
  </ng-container>

  <a class="truncate"
    [attr.aria-label]="MICROSOFT_COPYRIGHTS"
    [attr.title]="MICROSOFT_COPYRIGHTS">
    {{ MICROSOFT_COPYRIGHTS }}
  </a>


</div>

<ng-template #listTemplate
  let-list="list">
  <ul>
    <ng-container *ngFor="let action of list">
      <ng-container *ngIf="showLink(action)">
        <ng-container *ngIf="!isPopup(action); then link; else button"></ng-container>
      </ng-container>
      <ng-template #link>
        <li>
          <a class="link truncate"
            rel="noreferrer"
            [href]="getLinkByValue(action)"
            target="blank"
            [attr.aria-label]="action.title"
            [title]="action.title"
            [attr.role]="LINK_ROLE"
            [id]="action.id">
            {{action.title}}
            <i class="i-vi-open"></i>
          </a>
        </li>
      </ng-template>
      <ng-template #button>
        <li>
          <a class="link truncate"
            href="javascript:void(0)"
            [attr.aria-label]="action.title"
            [title]="action.title"
            [attr.role]="'button'"
            [id]="action.id"
            (appAccessibleClick)="emitPopup(action)">
            {{action.title}}
          </a>
        </li>
      </ng-template>
    </ng-container>
  </ul>
</ng-template>