<div class="sign-in-azure">
  <p>
    {{resources?.VIBuyWizardConnectToAzureText1}}
    <br>
    <br>
    {{resources?.VIBuyWizardConnectToAzureText2}}
    <br>
    <br>
  </p>
  <p>
      {{resources.BuyWizardNotAADRoles}}
      <br>
      {{resources.BuyWizardNotAADRolesOwner}}
  </p>
  <button
    class="btn truncate"
    id="viLoginAAD"
    (appAccessibleClick)="signInWithAAD()"
    [title]="resources.LoginSignInCorporateAccountTitle"
    [attr.aria-label]="resources.LoginSignInCorporateAccountTitle">
    <i class="i-vi-azure"></i>
    <span>{{ resources.SignInToAzure }}</span>
  </button>
  <div class="create-account-links">
      <a [href]="VIDEO_INDEXER_PRICING_LINK" role="link" target="_blank">{{resources.BuyWizardNotAADLinkPricing}}</a>
      <p class="azure-details">
          <span>{{resources?.BuyWizardNotAADDontHaveAccount}}</span><br>
          <a [href]="GET_STARTED_WITH_AZURE_FREE" role="link" target="_blank">{{resources.BuyWizardNotAADGetStartedAzure}}</a>
      </p>
  </div>
</div>
