<div class="strip-container"
  [class]="messageType"
  [ngClass]="{ spinner: hasSpinner, close: hasCloseButton, accessible: accessibilityMode, progress: progress }">
  <app-vi-spinner class="col spinner"
    *ngIf="hasSpinner"
    [size]="'tiny'"></app-vi-spinner>
  <div class="col text-container">
    <i *ngIf="iconClass"
      class="icon-text"
      [ngClass]="iconClass"></i>
    <div class="text"
      [ngClass]="{truncate: truncate}"
      [attr.title]="text"
      [attr.role]="isAlert ? 'alert':null"
      [innerHtml]="safeText"></div>
  </div>
  <div class="progress-bar-container"
    *ngIf="progress">
    <vi-ui-progress-bar class="progress-bar"
      [mode]="'determinate'"
      [value]="loadingProgress">
    </vi-ui-progress-bar>
  </div>
  <app-vi-action-button class="col op"
    *ngIf="action"
    [action]="action"
    [type]="ActionButtonType.SECONDARY"
    (actionClick)="handleActionClick()">
  </app-vi-action-button>
  <i *ngIf="hasCloseButton"
    tabIndex="0"
    class="col close i-vi-close-big"
    role="button"
    [attr.aria-label]="resources.CloseIconLabel"
    [title]="resources.CloseIconLabel"
    (appAccessibleClick)="closeStrip($event)"></i>
</div>